import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'home',
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '首页',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
    },
    {
        path: '/news',
        name: 'index',
        meta: {
            title: '新闻中心',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
    },
    {
        path: '/gonggao',
        name: 'index',
        meta: {
            title: '通知公告',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/gonggao/index.vue')
    },
    {
        path: '/newsInfo',
        name: 'newsInfo',
        meta: {
            title: '新闻详情',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/news/newsInfo.vue')
    },
    {
        path: '/gonggaoInfo',
        name: 'gonggaoInfo',
        meta: {
            title: '公告详情',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/gonggao/gonggaoInfo.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登陆页面',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
    },
    {
        path: '/search',
        name: 'search',
        meta: {
            title: '搜索',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/home/search.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
//function geturl(name, url) {
//    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || ""
//}
//router.beforeEach((to, from, next) => {
////    localStorage.setItem("token","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjE3MDM2NTU3NzksInVzZXJuYW1lIjoieHVzZXIifQ._FxNgsIAEFd9tGuZ0ppPai_PGY-8RZhqfGmwO7pwir3eMNHsElRtszIURFu2cYSEy0O0F4Ik7P9DrhEnBkzShg")
//    let token=localStorage.getItem('token')
//     if (!token && to.path !== '/login') {
//         next('/login')
//     } else {
//         next()
//     }
//   
//})
export default router
