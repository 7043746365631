import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import axios from "axios";
import '@/assets/font/iconfont.css'

import '@/assets/css/home.less'
import '@/assets/css/base.less'

// import 'amfe-flexible'
// import 'amfe-flexible/index.js';

import { Lazyload } from 'vant';
Vue.use(Lazyload);
import { setToken, getToken,removeToken } from '@/utils/auth'

 function geturl(name,url){
 	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || ""
 }

 router.beforeEach(async (to, from, next) => {
    // setToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjE3MDgzOTAwMTIsInVzZXJuYW1lIjoiRGVuZ0Z1cWlhbmcifQ.InV-0jX7j01ge0s79w5_JXicn8jik4PChD-zJGGznxy0KpSdEIRXcKuB5mUco0EtGEH6oEPL8JbL1x8p_nW4RA")
     const openID = getToken()

   // setopenID('ofU1pwk7VQclvzFkpxTrf-ZtGI9w')
//     const openID ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjE3MDgzOTAwMTIsInVzZXJuYW1lIjoiRGVuZ0Z1cWlhbmcifQ.InV-0jX7j01ge0s79w5_JXicn8jik4PChD-zJGGznxy0KpSdEIRXcKuB5mUco0EtGEH6oEPL8JbL1x8p_nW4RA"
 	if (openID) { // token设置 直接跳转 无则去换取
     next()
    } else {
//         const appid='ww00e842228c2afe3e'; //测试
         const appid='ww13b2eeb147a6f55f'; //中韩
     //   alert(appid)
     const redirect_uri=encodeURIComponent(window.location.href);
     const code = geturl('code',window.location.href) // 截取url上的code ,可能没有,则返回''空字符串
     if (code == null || code == '') { // 如果没有code，则去请求
       window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
     } else {
//         alert(code)
//         alert(process.env.VUE_APP_BASE_API)
       let param={
         code:code
       }
         axios.get(process.env.VUE_APP_BASE_API+'/dd/loginPortal', {params: param}).then(function (res) {
//             alert(123)
//             alert(JSON.stringify(res))
         if(res.data.code==200){
             console.log(res)
//             alert(res.data.data)
//             alert(res.data.data.token)
             setToken(res.data.data.token)
             next()
         }else if(res.data.code==401){ // token过期重新刷新请求
             removeToken()
           window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
//            alert(res.data)
         } else {
//           alert(res.data.msg)
//           alert(123)
         }
       }).catch(err=>{
//           alert(JSON.stringify(err))
       })

     }

    }

 })

//适配苹果底部
Vue.directive('isIphoneX', {
  bind: function (el, binding) {
      const u = navigator.userAgent;
      const _local = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      let isIphoneX = false
      if (_local && window.screen.height) {
          isIphoneX = window.screen.height > 750 ? true : false
      }
      if (isIphoneX) {
          if (binding.value === 'margin') {
              console.log('use margin')
              el.setAttribute('style', 'margin-bottom: 8vw !important;')
          } else if (binding.value === 'height') {
              el.setAttribute(
                  'style',
                  ` padding-bottom: 15vh !important;min-height: 85vh !important;`
              )
          } else {
              el.setAttribute('style', 'padding-bottom: 8vw !important;')
          }
      } else {
          if (binding.value === 'height') {
              el.setAttribute(
                  'style',
                  ` padding-bottom: 11vh !important;min-height: 89vh !important;`
              )
          }
      }
  },
  update: function (el, binding) { }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



